@import "../../styles/variables";
@import "../../styles/mixin";

.program-management-container {
  .filter-tags {
    font-size: 13px;
    padding: 2px 8px;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 10px;

    .blue-btn {
      margin: 0;
    }

    .green-btn {
      margin: 0;
      min-width: 4rem;
      @include flexbox-center;
      display: inline-flex;
      padding: 0.625rem 1.5rem;
      @include font-medium;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.02857em;
      text-align: center;
      border-radius: $btn-border-radius;
      outline: 0;
      border: 0;
      background: #04c57b;
      color: $sidebar-font;
      transition: all 150ms ease-in 0s;
      cursor: pointer;

      &.disabled {
        background: $disabled-bg;
        cursor: not-allowed;
        border: 1px solid #999999;
      }
      &.disabled:hover {
        box-shadow: none;
      }
      &:hover {
        box-shadow: $btn-box-shadow;
      }
    }

    .white-btn {
      margin: 0;
      min-width: 4rem;
      @include flexbox-center;
      display: inline-flex;
      padding: 0.625rem 1.5rem;
      @include font-medium;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.02857em;
      text-align: center;
      border-radius: $btn-border-radius;
      outline: 0;
      border: 0;
      background: #fff;
      color: #333;
      transition: all 150ms ease-in 0s;
      cursor: pointer;
      border: 1px solid #999999;

      &.disabled {
        background: $disabled-bg;
        cursor: not-allowed;
        border: 1px solid #999999;
      }
      &.disabled:hover {
        box-shadow: none;
      }
      &:hover {
        box-shadow: $btn-box-shadow;
      }
    }
  }

  .search-bar-wrapper {
    position: relative;
  }

  .error-message {
    position: absolute;
    top: -10px;
    font-size: 13px;
    color: red;
    white-space: nowrap;
  }

  .csv-btn-container {
    display: flex;
    gap: 20px;

    &.disabled {
      background-color: $disabled-bg
    }
  }

  .data-table-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0;

    .pagination-wrap {
      margin: 0 0 0 15px;
    }
  }

  .data-table-rows-limit {
    max-width: 150px;
    width: 100%;
  }

  .resizer-handle {
    background: #dddddd;
  }

  .data-table-rows-count {
    font-size: 14px;
    margin: 0 0 0 auto;
  }

  .css-fynbd-CELL_CONTAINER_STYLE-Cell {
    div {
      overflow: visible !important;
    }
  }

  input[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Custom styles */
    width: 20px;
    height: 20px;
    border: 2px solid #467FF7;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all .2s ease-in-out;
  }

  .status-tag {
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 14px;

    &.rejected {
      background: #FF6B7233;
      color: #FF6B72;
    }

    &.live {
      background-color: #04D18233;
      color: #04D182;
    }

    &.pending {
      background-color: #3E79F733;
      color: #3E79F7;
    }

    &.not-connected {
      background: #FFCC0026;
      color: #856400;
    }

    &.hold-uc {
      background: #FFCC0026;
      color: #856400;
    }

    &.Accepted {
      background-color: #04D18233;
      color: #04D182;
    }

    &.Denied {
      background: #FF6B7233;
      color: #FF6B72;
    }

    &.Deactivated {
      background-color: #00000033;
      color: #000000;
    }

    &.under-consideration {
      background-color: #3E79F733;
      color: #3E79F7;
    }

    &.Ended {
      background-color: #00000033;
      color: #000000;
    }

    &.not_joined {
      background: #FFCC0026;
      color: #856400;
    }
  }

  .hint-selected-offers {
    position: fixed;
    right: 25px;
    background-color: #3E79F733;
    color: #3E79F7;
    font-size: 16px;
    padding: 6px;
    border-radius: 6px;
  }

  td {
    overflow: hidden;
  }

  input[type="checkbox"]:checked {
    background: #467FF7;
  }

  input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input[type="checkbox"]:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(70, 127, 247, 0.5);
  }
}


