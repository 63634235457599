@import "../../styles/variables";
@import "../../styles/mixin";

.changed-campaigns-details {

  &__heading {
    text-align: left;
    float: right;
    max-width: 600px;
    h3 {
      font-size: 16px;
      span {
        margin-left: 10px;
        font-weight: 400;
      }
    }
  }

  .error-container {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .error-message {
      font-size: 24px;
      text-align: center;
    }
  }

  .buttons-container {
    display: flex;
    gap: 10px;
  }
}
