@import '../../../styles/mixin';
@import '../../../styles/variables';

.form-login-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include flexbox-center;
  @include flex-direction-column;
  width: 100%;
  min-height: 100vh;
  @include bg-cover;

  .form-inner-wrap {
    width: 25rem;
    height: 26rem;
    padding: 0 1.5rem 0 1.5rem;

    .title-wrap {
      justify-content: center;
      font-size: 1.5rem;
    }
  }

  form {
    min-width: 20rem;
    padding: 1.5rem 2.5rem;

    .input-wrap {
      &:nth-child(2) {
        margin-bottom: 5px;
      }

      input {
        width: 100%;
      }

      &.error-input {
        input {
          border-color: $error;
        }
      }
    }

    .forgot-pass-wrap {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .forgot-password-link {
        display: block;
        font-size: 14px;
        color: #467FF7;
        border: none;
        padding: 0;
        background: transparent;
      }
    }
  }


  .error-text.login-error-text {
    position: absolute;
    left: 0;
    bottom: -7px;
  }

  .form-submit {
    position: relative;
    margin: 1.5rem 0 .5rem;
    outline: 0;
    border: 0;
    min-width: 2rem;
    width: 70%;
    @include flexbox-center;
    @include font-bold;
    border-radius: $btn-border-radius;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    transition: all 150ms ease-in 0s;
    min-height: 2.5rem;
    padding: 0.625rem 1.5rem;
    background-color: $btn-bg;
    color: $sidebar-font;
    background-size: 150% !important;
    background-position-x: 25% !important;
    cursor: pointer;

    &:hover {
      box-shadow: $btn-box-shadow;
    }

    &:disabled {
      background-color: gray;
      border-color: gray;
    }

    &.successfully {
      background-color: #5cb85c;
    }
  }
}

.user-env-select {
  max-width: 150px;
  width: 150px;
  font-size: 14px;
  position: absolute;
  left: 30px;
  z-index: 9999;
}