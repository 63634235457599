@import "../../styles/variables";
@import "../../styles/mixin";

.filters {
  display: flex;
  align-items:flex-end;
  margin-bottom: 20px;

  button {
    margin-bottom: 0;
    min-height: 45px;
  }
}

.filter-input__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 5px;

  label,
  legend {
    margin-bottom: .35rem;
    @include font-medium;
    font-size: .75rem;
    line-height: 1rem;
    color: $sidebar-bg;
  }
}

.filter-input {
  border: 1px solid #9b9b9b;
  padding: 14px;
  border-radius: 10px;
}