@import "../../../styles/mixin";
@import "../../../styles/variables";

.add-item-popup-container {
  .form-wrap {
    width: 100%;

    .form-block {
      margin: .5rem 0;
      //padding: .5rem;
      border-radius: $btn-border-radius;
      background-color: $tabs-bg;
    }

    .config-item-block {
      margin: .5rem auto;

      .form-error {
        position: absolute;
        bottom: 0;
        left: .85rem;
        color: $error;
      }
    }

    .without-config-item-block {
      margin: .5rem 0;

      &.form-error input {
        border-color: $error;
      }

      .form-error {
        left: 10px;
        font-size: .85rem;
        color: $error;
      }
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    .config-item-block,
    .without-config-item-block {
      padding: .35rem .5rem;

      &.form-error input {
        border-color: $error;
      }

      &.disabled {
        display: none;
      }

      label {
        bottom: -.35rem;
        width: initial;
        font-size: .75rem;
        letter-spacing: 0.00938em;
        color: rgb(123, 128, 154);

        &:after {
          content: none;
        }
      }

      &.disabled {
        .react-tagsinput-tag {
          display: none;
        }
      }
    }

    .react-tagsinput {
      width: 70%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border: none;

      input {
        width: 100%;
        padding: 0.75rem 1.25rem;
        background-color: $table-bg;
        color: $logout-font;

        &:active {
          color: $sidebar-bg;
        }

        &::placeholder {
          color: $logout-font;
        }
      }
    }

    .react-tagsinput-tag {
      padding: .35rem .5rem;
      background-color: rgb(25, 25, 25);
      color: rgb(255, 255, 255);
      border: 0;
      border-radius: 1rem;

      a::before {
        background-color: $popup-bg;
        color: rgb(25, 25, 25);
        border-radius: 100%;
        margin: 0 -.2rem 0 .3rem;
        padding: 0 .2rem 0 .05rem;
      }
    }

    form .after-checkbox-block {
      order: 10;
        h3 {
          font-size: .875rem;
          font-weight: 500;
          margin-bottom: 5px;
          color: #467FF7;
        }
    }
    form .scrolled-block {
       p {
        font-size: .875rem;
        font-weight: 500;
       }
      padding: 5px 10px;
      max-height: 100px;
      overflow-y: scroll;
      background-color: #f7f7f7;
    }
    form .scrolled-block::-webkit-scrollbar-thumb {
      background-color: #464646;
      border-radius: 3px;
      border: 3px solid #464646;
  }
    form .scrolled-block li {
      font-size: .8rem;
      margin-left: 10px;
    }
    form .checkbox-block, 
    form .checkbox-block2 {
      @include flexbox-end;
      align-items: center;
      @include flex-direction-row-reverse;
      order: 9;

      .toggle-button {
        position: relative;
        display: inline-block;
        width: 3rem;
        height: 1.5rem;
        margin-left: 1.5rem;
        padding: 0;
        vertical-align: top;
        background-color: #8698b2;
        border: none;
        border-radius: 50px;
        outline: none;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: 0.3s;

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          left: .2rem;
          top: .17rem;
          width: 1.125rem;
          height: 1.125rem;
          border-radius: 50%;
          background-color: $popup-bg;
          transform: translateX(0);
          transition: 0.3s;
        }

        &:checked {
          background-color: $sidebar-bg;

          &::after {
            transform: translateX(125%);
          }
        }
      }

      .toggle-label {
        bottom: -0.75rem;
        @include font-medium;
        margin: 0;
        font-size: 0.75rem;
        color: rgb(123, 128, 154);
      }
    }
  }
}

.item-uppercase {
  text-transform: uppercase;
}

.config-btns-loader-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexbox-center;
  backdrop-filter: saturate(-100%) blur(4px);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: $border-radius;
  box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem;
  z-index: 1;
}

.config-btns-message-block {
  margin-bottom: -1rem;
}

.hint {
  font-size: .75rem;
  margin: 6px;
  color: rgb(123, 128, 154);
}
