@import "../../styles/mixin";
@import "../../styles/variables";

.csv-btn-wrap {
  //margin: 0 0 0 .5rem;
  &.disabled {
    cursor: not-allowed;

    .blue-btn {
      background: #808080;
      pointer-events: none;
    }
  }

  .blue-btn {
    &.csv {
      margin-left: 15px;
    }
  }
}

.blue-btn {
  min-height: 2.5rem;
  min-width: 4rem;
  @include flexbox-center;
  display: inline-flex;
  padding: 0.625rem 1.5rem;
  margin-bottom: 1rem;
  @include font-medium;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.02857em;
  text-align: center;
  border-radius: $btn-border-radius;
  outline: 0;
  border: 0;
  background: $btn-bg;
  color: $sidebar-font;
  transition: all 150ms ease-in 0s;
  cursor: pointer;

  &.disabled {
    background: $disabled-bg;
    cursor: not-allowed;
    border: 1px solid #999999;
  }
  &.disabled:hover {
    box-shadow: none;
  }

  &:hover {
    box-shadow: $btn-box-shadow;
  }
}

.new-blue-btn {
  background: #1890FF;

  &.disabled {
    background: $disabled-bg;
    cursor: not-allowed;
    border: 1px solid #999999;
  }
}

.csv-btn-error {
  display: block;
  margin-right: .5rem;
  font-size: .95rem;
  color: $error;
}

.btn-ml {
  margin-left: .75rem;
}

.discard-btn {
  min-height: 2.5rem;
  min-width: 9rem;
  @include flexbox-center;
  display: inline-flex;
  padding: 0.625rem 1.5rem;
  margin-bottom: 1rem;
  @include font-medium;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.02857em;
  text-align: center;
  border-radius: $btn-border-radius;
  outline: 0;
  border: 0;
  background: #F0F2F5;
  color: $sidebar-bg;
  margin-right: 8px;
  transition: all 150ms ease-in 0s;
  cursor: pointer;

  &.disabled {
    background: $disabled-bg;
    cursor: not-allowed;
    border: 1px solid #999999;
  }
  &.disabled:hover {
    box-shadow: none;
  }

  &:hover {
    box-shadow: $btn-box-shadow;
  }
}
