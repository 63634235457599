@import "../../../styles/mixin";
@import "../../../styles/variables";

.item-create-button {
  color: #fff;

  &:disabled {
    background: $disabled-bg;
    cursor: not-allowed;
    border: 1px solid #999999;
  }
}