@import "../../../styles/variables";
@import "../../../styles/mixin";

.item-delete-button {
  @include flexbox-center;
  min-width: 2.25rem;
  height: 2.25rem;
  color: $fail;
  background: $table-bg;
  border-radius: $btn-border-radius;
  border: 1px solid $fail;
  transition: $transition;
  cursor: pointer;

  &:hover {
    background-color: #ff6b7226;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .4;

    &:hover {
      background-color: $table-bg;
    }
  }

  .item-delete-button__text {
    margin: 0 0 0 5px;
  }

  .loader {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;

    div {
      width: 5px;
      height: 5px;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;

      &:nth-child(1) {
        left: 3px;
        animation: loaderAnimation1 0.6s infinite;
      }
      &:nth-child(2) {
        left: 3px;
        animation: loaderAnimation2 0.6s infinite;
      }
      &:nth-child(3) {
        left: 21px;
        animation: loaderAnimation2 0.6s infinite;
      }
      &:nth-child(4) {
        left: 39px;
        animation: loaderAnimation3 0.6s infinite;
      }
    }
  }
}
