@import "../../styles/variables";
@import "../../styles/mixin";

.changed-campaigns-container {
  .react-datepicker__input-container input {
    margin: 0 !important;
    max-width: 230px;
  }

  .blue-btn {
    margin: 0 0 0 10px;
  }

  .show-details {
    @include flexbox-center;
    width: 2.25rem;
    height: 2.25rem;
    background-color: $btn-bg;
    border: 1px solid $btn-bg;
    border-radius: $border-radius;
    transition: $transition;
    cursor: pointer;

    &:hover {
      background-color: $btn-bg-hover;
    }
  }

  .datepicker-container {
    position: relative;
    display: flex;
    gap: 10px;

    .datepicker-range-hint {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -30px;
      left: -30px;
      flex-direction: column;
      background: rgba(62, 121, 247, 0.15);
      border: 1px solid #467FF7;
      border-radius: 6px;
      font-size: 14px;
      padding: 3px;
      margin-bottom: 8px;
      z-index: 100;
    }

    .submit-selected-data {
      @include flexbox-center;
      display: inline-flex;
      padding: 0.625rem 1.5rem;
      @include font-medium;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.02857em;
      text-align: center;
      border-radius: $btn-border-radius;
      outline: 0;
      border: 0;
      background: $btn-bg;
      color: $sidebar-font;
      transition: all 150ms ease-in 0s;
      cursor: pointer;
    }
  }
}

