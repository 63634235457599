@import "../../styles/variables";
@import "../../styles/mixin";

.data-table-filters-wrap {
  display: flex;
  flex-wrap: wrap;
}

.data-table-order-wrap,
.data-table-filter-wrap {
  padding: 10px 10px 5px;
  border: 1px solid #ddd;
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &:after {
    position: absolute;
    top: -9px;
    left: 5px;
    background-color: #f8f9f9;
    font-size: 11px;
    padding: 0 10px;
  }
}

.data-table-order-wrap {
  width: 400px;
  margin: 10px 10px 10px 0;
  &:after {
    content: 'Order';
  }
}

.data-table-filter-wrap {
  &:after {
    content: 'Filter';
  }
}

.sort-select {
  margin: 0 10px 5px 0;
  padding: 16px 0 0;
  position: relative;
  flex-shrink: 0;

  &:after {
    position: absolute;
    top: 3px;
    left: 10px;
    font-size: 11px;
    line-height: 1;
  }
}

.sort-input {
  height: 30px;
  font-size: 12px;
  color: #333333;
  border: 1px solid #cccccc;
  padding: 0 8px;
  border-radius: 4px;
  margin: 10px 10px 0 0;
}

.react-datepicker__input-container .sort-datepicker {
  border-radius: 4px;
  border: 1px solid #cccccc;
  height: 30px;
  padding: 0;
  margin: 11px 10px 0 0;
  max-width: 160px;
  font-size: 12px;

  &:hover {
    box-shadow: none;
  }
}

.order-by-select {
  max-width: 170px;
  width: 100%;

  &:after {
    content: 'Order by:';
  }
}

.order-direction-select {
  max-width: 170px;
  width: 100%;

  &:after {
    content: 'Order direction:';
  }
}

.search-in-column-wrap {
  border: 1px solid #ddd;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.search-in-column-select {
  width: 180px;

  &:after {
    content: 'Search in column:';
  }
}

.form-buttons {
  display: flex;
  padding: 0.5rem 2.5rem;
}

.data-table-search-btns-wrap {
  display: flex;
  align-items: center;
  margin: 9px 0 0;

  button, .clear-filters-btn {
    margin: 0 5px 0;
    height: 30px;
    min-height: unset;

    &:disabled {
      opacity: .4;
      cursor: default;
    }
  }
}