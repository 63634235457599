@import "../../styles/variables";

.modal_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.modal__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#30364B, #242939);
  opacity: 0.5;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 27px;
  background: #fff;
  border-radius: 16px;
  color: $sidebar-bg;
  width: 574px;
  height: 379px;
  z-index: 2;
}

.modal__title-main {
  display: block;
  color: #000;
  font-width: 700;
  font-size: 24px;
  color: $sidebar-bg;
}

.modal__title-inner {
  font-size: 14px;
  font-weight: 700;
  color: $sidebar-bg;
}

.divider {
  border: 1px solid #8F9EB0;
  opacity: 0.25;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 54px;
}

.modal__content-block {
  display: flex;
  flex-direction: column;
  width: 318px;
}

.modal__select {
  border: 1px solid #EDEDED;
  background: #fff;
  border-radius: 4px;
  color: $sidebar-bg;
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 2px 4px 4px 2px;
}

.modal__btns {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

}
