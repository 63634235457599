.notification-popup-cover {
  position: fixed;
  bottom: 20px;
  top: 84px;
  right: 0;
  z-index: 7;
  height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
    .notifications {
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .notification-popup {
      background-color: #fff;
      width: 300px;
      padding: 12px 15px;
      margin: 0 5px 5px 0;
      box-sizing: border-box;
      box-shadow: 1px 1px 3px #d5d5d5;
      border-radius: 3px;
      position: relative;
        .notification-popup-text {
          font-size: 12px;
          max-width: 95%;
            img {
              float: left;
              margin: 0 15px 5px 0;
            }
            h6 {
              font-size: 14px;
              margin: 3px 0 9px;
            }
            a {
              color: #2a49cf;
              text-decoration: underline;
            }
        }
        .notification-popup-close-button {
          border: none;
          background-color: transparent;
          font-size: 22px;
          font-weight: 100;
          transform: rotate(45deg);
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 5px;
          right: 1px;
          color: #ddd;
        }
    }
}

.notification-popup:hover {
  .notification-popup-close-button {
    color: #333;
  }
}
* {
  scrollbar-width: thin;
  scrollbar-color: #ededed transpanent;
}

*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 3px;
  border: 3px solid #ffffff;
}