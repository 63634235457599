@import "../../styles/variables";
@import "../../styles/mixin";

.affiliate-network-offers-container {
  .filter-tags {
    font-size: 13px;
    padding: 2px 8px;
  }

  .blue-btn {
    margin: 0;
  }

  .search-bar-wrapper {
    position: relative;
  }

  .error-message {
    position: absolute;
    top: -10px;
    font-size: 13px;
    color: red;
    white-space: nowrap;
  }

  form {
    margin-bottom: 25px;

    .yieldkit-accounts-btn {
      margin-bottom: 5px;
    }
  }
}

.csv-btn-container {
  display: flex;
  gap: 20px;

  &.disabled {
    background-color: $disabled-bg
  }
}

.data-table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;

  .pagination-wrap {
    margin: 0 0 0 15px;
  }
}

.data-table-rows-limit {
  max-width: 150px;
  width: 100%;
}

.resizer-handle {
  background: #dddddd;
}

.data-table-rows-count {
  font-size: 14px;
  margin: 0 0 0 auto;
}

.prev-page-hint {
  display: flex;
  flex-direction: column;
  background: rgba(62, 121, 247, 0.15);
  border: 1px solid #467FF7;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px;
  margin-bottom: 8px;
}
