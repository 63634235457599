@import "../../styles/variables";
@import "../../styles/mixin";

.tab-list {
  display: flex;
  gap: 5px;
  text-transform: capitalize;
  color: $sidebar-bg;
  font-size: .875rem;
  margin-bottom: 10px;

  .tab {
    background: $tabs-bg;
    padding: 11px 20px;
    border-radius: 4px;
    cursor: pointer;

    &.active {
      background: #fff;
      color: $btn-bg;
      box-shadow: 0 0 3px 0 rgba(0,0,0,0.75);
    }
  }
}